export function formatLocaleDateString(value, locale = 'ch-DE') {
  return new Date(value).toLocaleDateString(locale);
}

export function isoFormatDMY(d) {
  const date = new Date(d);
  const pad = (n) => {
    return ( n< 10 ? '0' :  '') + n
  }
  return pad(date.getUTCDate()) + '.' + pad(date.getUTCMonth() + 1) + '.' + date.getUTCFullYear();
}
