import { useState, useEffect } from "react";
import { makeRequest } from "../../lib/makeRequest";
import { roundTwoDecimal } from "../../utils/math.helpers";
import ProductList from "./ProductList";
import { useTranslation } from 'react-i18next';

const MIN_REIMBURSEMENT_AMOUNT = 50;
const SUCCESS_MSG_TIMEOUT = 10000; // milliseconds
const formInit = {
  name: "",
  strasse: "",
  plz: "",
  ort: "",
  telefonnummer: "",
  email: "",
  hauptlieferant: "",
  kundennummer: "",
  packungen: "",
  lagerbestand_check: false,
  rabattaktion_check: false,
};

const ReimbursementForm = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorReimbursement, setErrorReimbursement] = useState(false);
  const [form, setForm] = useState(formInit);

  const handleChange = (e) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleChangeCB = (e) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const totalAmountReimbursement = await sumAllReimbursement();
      if (isReimbursementLessThan(totalAmountReimbursement)) {
        setErrorReimbursement(true);
        return;
      }
      setErrorReimbursement(false);
      const data = {
        ...form,
        products,
        reimbursement_amount: totalAmountReimbursement,
        language: i18n.language,
      };
      const result = await makeRequest.post("/submissions", {
        data,
      });
      if (result.status === 200) {
        setSuccess(true);
        resetForm();
        setTimeout(() => {
          setSuccess(false);
        }, SUCCESS_MSG_TIMEOUT);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePackage = (e, { id, attributes }) => {
    const inputName = e.target.name;
    const packagesNumber = e.target.value;
    const refundAmount = calculateReimbursement(
      packagesNumber,
      attributes.fap_alt,
      attributes.fap_neu
    );
    updateProduct(id, inputName, packagesNumber, refundAmount);
  };

  const sumAllReimbursement = () => {
    const totalReimbursement = products.reduce(
      (total, product) => {
        total += (product.attributes.refund_amount || 0)
        return total;
      }, 0);
    return totalReimbursement;
  };

  function updateProduct(productId, paramName, paramValue, refundAmount) {
    const newProduct = products.map((product) => {
      if (product.id === productId) {
        return {
          ...product,
          attributes: {
            ...product.attributes,
            [paramName]: paramValue,
            refund_amount: refundAmount,
          },
        };
      }
      return product;
    });
    setProducts(newProduct);
  }

  const resetForm = () => {
    setForm(formInit);
    const resetProducts = products.map((product) => {
      return {
        ...product,
        attributes: {
          ...product.attributes,
          packungen: "",
          refund_amount: "",
        },
      };
    });
    setProducts(resetProducts);
  };

  useEffect(() => {
    const fetchData = async () => {
      await makeRequest.get(`/products?populate=*`)
        .then((response) => {
          if (!response.data.data) return;
          setProducts(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching products: ", error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  if (products && products.length === 0) return (
    <div className="space-y-4 divide-y">
      <DifferenceBetweenText t={t} />
      <NoPriceCutPeriod t={t} />
    </div>
  );

  return (
    <form id="reimbursementForm" onSubmit={handleSubmit}>
      <div className="space-y-8">

        <DifferenceBetweenText t={t} />

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("name")} <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("street")} <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              name="strasse"
              value={form.strasse}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("zip_code")} <span className="text-red-600">*</span>
            </label>
            <input
              type="number"
              name="plz"
              value={form.plz}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("location")} <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              name="ort"
              value={form.ort}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("phone")} <span className="text-red-600">*</span>
            </label>
            <input
              type="tel"
              name="telefonnummer"
              value={form.telefonnummer}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              Email <span className="text-red-600">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("supplier")} <span className="text-red-600">*</span>
            </label>
            <select
              name="hauptlieferant"
              value={form.hauptlieferant}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              id="azhcpportal-form_contactaz_Grossist"
            >
              <option value=""></option>
              <option value="Alloga AG, 3401 Burgdorf">
                Alloga AG, 3401 Burgdorf
              </option>
              <option value="Amedis-UE AG, 5035 Unterentfelden">
                Amedis-UE AG, 5035 Unterentfelden
              </option>
              <option value="Amedis-UE SA, 1070 Puidoux">
                Amedis-UE SA, 1070 Puidoux
              </option>
              <option value="Galexis AG, 4704 Niederbipp">
                Galexis AG, 4704 Niederbipp
              </option>
              <option value="Galexis SA, 1024 Ecublens">
                Galexis SA, 1024 Ecublens
              </option>
              <option value="Galenicare SA, 3027 Bern">
                Galenicare SA, 3027 Bern
              </option>
              <option value="Leimapharm 134416, 4106 Therwil">
                Leimapharm 134416, 4106 Therwil
              </option>
              <option value="Medicom Pharma, 3646 Einigen">
                Medicom Pharma, 3646 Einigen
              </option>
              <option value="MediService AG, 4528 Zuchwil">
                MediService AG, 4528 Zuchwil
              </option>
              <option value="Pharma Focus AG, 4142 Münchenstein">
                Pharma Focus AG, 4142 Münchenstein
              </option>
              <option value="Pharma Focus SA, 1680 Romont">
                Pharma Focus SA, 1680 Romont
              </option>
              <option value="Pharmapool AG, 9443 Widnau">
                Pharmapool AG, 9443 Widnau
              </option>
              <option value="Regiomed GmbH, 5610 Wohlen">
                Regiomed GmbH, 5610 Wohlen
              </option>
              <option value="Unione Farmaceutica, 6917 Barbengo">
                Unione Farmaceutica, 6917 Barbengo
              </option>
              <option value="Voigt AG, 8590 Romanshorn">
                Voigt AG, 8590 Romanshorn
              </option>
              <option value="Zur Rose Suisse AG, 8500 Frauenfeld">
                Zur Rose Suisse AG, 8500 Frauenfeld
              </option>
            </select>
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">
              {t("customer_number")} <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              name="kundennummer"
              value={form.kundennummer}
              onChange={handleChange}
              className="border border-gray-600 rounded py-1 px-2"
              required
            />
          </div>
        </div>

        <RequiredFields t={t} />

        {error ? (
          "something went wrong!"
        ) : loading ? (
          "loading..."
        ) : (
          <div className="flex overflow-x-auto">
            <ProductList
              products={products}
              onPackageChange={handleChangePackage}
            />
          </div>
        )}

        {errorReimbursement && <ErrorMinReimbursement t={t} />}

        <ReceiveBonus t={t} />

        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-row space-x-4 items-center">
            <input
              type="checkbox"
              id="lagerbestand_check"
              name="lagerbestand_check"
              checked={form.lagerbestand_check}
              onChange={handleChangeCB}
              className="border border-gray-600 rounded py-1 px-2 checked:bg-clr-purple"
              required
            />
            <label htmlFor="lagerbestand_check">
              {t("confirm_msg")}<span className="text-red-600">*</span>
            </label>
          </div>
          <div className="flex flex-row space-x-4 items-center">
            <input
              type="checkbox"
              id="rabattaktion_check"
              name="rabattaktion_check"
              checked={form.rabattaktion_check}
              onChange={handleChangeCB}
              className="border border-gray-600 rounded py-1 px-2 checked:bg-clr-purple"
              required
            />
            <label htmlFor="rabattaktion_check">
              {t("certify_msg")}<span className="text-red-600">*</span>
            </label>
          </div>
        </div>

        <div className="flex col-span-2">
          <button
            type="submit"
            className="bg-clr-purple text-white font-bold py-2 px-6 rounded disabled:opacity-50"
          >
            {t("submit")}
          </button>
        </div>

        {success && <SuccessMessage t={t} />}
      </div>
    </form>
  );
};

export default ReimbursementForm;

function calculateReimbursement(packagesNumber, oldPrice, newPrice) {
  if (packagesNumber === "") return null;
  return roundTwoDecimal((oldPrice - newPrice) * Number(packagesNumber));
}

function isReimbursementLessThan(reimbursement) {
  return reimbursement !== null && reimbursement < MIN_REIMBURSEMENT_AMOUNT;
}

const NoPriceCutPeriod = ({ t }) => {
  return (
    <div className="space-y-4 font-bold pt-4">
      <p>{t("no_pricecut_period")}</p>
    </div>
  );
};

const ErrorMinReimbursement = ({ t }) => {
  return (
    <div className="space-y-2">
      <div className="bg-red-700 py-2 px-4 rounded">
        <p className="text-white font-bold">
          {t("error_min")}
        </p>
      </div>
    </div>
  );
};

const SuccessMessage = ({ t }) => {
  return (
    <div className="space-y-2">
      <div className="bg-green-700 py-2 px-4 rounded">
        <p className="text-white font-bold">
          {t("success_msg")}
        </p>
        <p className="text-white font-bold" dangerouslySetInnerHTML={{ __html: t("question_msg") }}></p>
      </div>
    </div>
  );
};

const RequiredFields = ({ t }) => {
  return (
    <div className="flex flex-col space-y-2">
      <p>{t("required_fields")}</p>
    </div>
  );
};

const DifferenceBetweenText = ({ t }) => {
  return (
    <div className="flex flex-col space-y-4 divide-y">
      <div className="space-y-2 ">
        <p>{t("difference_between1")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("difference_between2") }}></p>
        <p>{t("difference_between3")}</p>
      </div>
    </div>
  );
};

const ReceiveBonus = ({ t }) => {
  return (
    <div className="flex flex-col space-y-2">
      <p>
        {t("receive_bonus")}
      </p>
    </div>
  );
};
