
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import AZLogo from "./astrazeneca.svg";


const Header = () => {

    return (
        <header className="bg-white py-4 border-b">
            <div className="container">
                <div className="flex justify-between items-center">
                    <img src={AZLogo} alt="AstraZeneca" />
                    <LanguageSwitch />
                </div>
            </div>
        </header>
    )
}

export default Header;
