
import { isoFormatDMY } from "../../utils/date.helpers";

const Product = ({id, attributes, onPackageChange}) => {
  return (
    <tr>
        <td className="border border-gray-600 p-3">{isoFormatDMY(attributes.pricecut_date)}</td>
        <td className="border border-gray-600 p-3">{attributes.pharmacode}</td>
        <td className="border border-gray-600 p-3">{attributes.sku}</td>
        <td className="border border-gray-600 p-3">{attributes.name}</td>
        <td className="border border-gray-600 p-3">{attributes.dosage}</td>
        <td className="border border-gray-600 p-3">{attributes.package_size}</td>
        <td className="border border-gray-600 p-3">{attributes.fap_alt}</td>
        <td className="border border-gray-600 p-3">{attributes.pp_alt}</td>
        <td className="border border-gray-600 p-3">{attributes.fap_neu}</td>
        <td className="border border-gray-600 p-3">{attributes.pp_neu}</td>
        <td className="border border-gray-600 p-3">
          <input
          type="number"
          min={0}
          value={attributes.packungen || ""}
          onChange={(e) => onPackageChange(e, {id, attributes})}
          id="packungen"
          name="packungen"
          className="border border-gray-400 w-full py-1 px-2"
          required
          />
        </td>
    </tr>
  )
}

export default Product;
