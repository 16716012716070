import Product from "./Product";
import { useTranslation } from 'react-i18next';

const ProductList = ({ products, onPackageChange }) => {
  const { t } = useTranslation();
  return (
    <table className="table-auto border border-gray-600 w-full">
      <thead>
        <tr>
          <th className="border border-gray-600 p-3">{t("product_table.pricecut_date")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.pharmacode")}</th>
          <th className="border border-gray-600 p-3">SKU</th>
          <th className="border border-gray-600 p-3">{t("product_table.product")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.dosage")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.packing_size")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.exmnf_old")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.pp_old")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.exmnf_new")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.pp_new")}</th>
          <th className="border border-gray-600 p-3">{t("product_table.number_packages")}</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <Product
            key={product.id}
            {...product}
            onPackageChange={onPackageChange}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ProductList;
