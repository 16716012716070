import "./LanguageSwitch.css";
import { useTranslation } from "react-i18next";

function LanguageSwitch() {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const isCurrentLangDE = i18n.language === "de" ? "active" : "";
  const isCurrentLangFR = i18n.language === "fr" ? "active" : "";
  const isCurrentLangIT = i18n.language === "it" ? "active" : "";

  return (
    <div className="flex flex-row space-x-3 justify-start">
      <button
        onClick={() => changeLanguage("de")}
        className={`button-lang ${isCurrentLangDE}`}
      >
        DE
      </button>
      <button
        onClick={() => changeLanguage("fr")}
        className={`button-lang ${isCurrentLangFR}`}
      >
        FR
      </button>
      <button
        onClick={() => changeLanguage("it")}
        className={`button-lang ${isCurrentLangIT}`}
      >
        IT
      </button>
    </div>
  );
}

export default LanguageSwitch;
