import AZLogo from "./astrazeneca-logo.svg";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n} = useTranslation();
  const policyID = "0ecfe2e0";
  const cookiereportHref = `policy.cookiereports.com/${policyID}-${i18n.language}.html`;
  const globalPrivacyHref = `https://www.azprivacy.astrazeneca.com/europe/switzerland/ch-${i18n.language}/privacy-notices.html`;
  const termsHref = i18n.language === "it" ? `https://www.astrazeneca.ch/en/terms-of-use.html` : `https://www.astrazeneca.ch/${i18n.language}/terms-of-use.html`;
  const contactHref = i18n.language === "it" ? `https://www.astrazeneca.ch/en/contact-us.html` : `https://www.astrazeneca.ch/${i18n.language}/contact-us.html`;

  return (
    <footer className="bg-clr-purple py-6">
      <div className="container">
        <div className="flex lg:flex-row flex-col lg:items-center justify-between lg:space-y-0 space-y-4">
          <div className="flex flex-col space-y-2">
            <div>
              <ol className="text-white list-none lg:inline-flex lg:space-x-4">
                <li><a href={globalPrivacyHref} target="_blank" rel="noreferrer" className="text-white hover:underline">{t("footer.privacy")}</a></li>
                <li><a href={termsHref} target="_blank" rel="noreferrer" className="text-white hover:underline">{t("footer.terms")}</a></li>
                <li><a href={cookiereportHref} target="_blank" rel="noreferrer" className="text-white hover:underline">{t("footer.cookie")}</a></li>
                <li><a href={contactHref} target="_blank" rel="noreferrer" className="text-white hover:underline">{t("footer.contact")}</a></li>
              </ol>
            </div>
            <p className="text-white">© 2023 AstraZeneca | CH-7535 03/2023</p>
          </div>
          <div>
            <a
              href="https://www.astrazeneca.ch/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={AZLogo} alt="AstraZeneca" className="w-[120px]" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
